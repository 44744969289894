import React, { useState } from 'react';
import './ApplicationForm.css';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const firebaseConfig ={
  apiKey: process.env.REACT_APP_apiKey,
  authDomain: process.env.REACT_APP_authDomain,
  projectId: process.env.REACT_APP_projectId,
  storageBucket: process.env.REACT_APP_storageBucket,
  messagingSenderId: process.env.REACT_APP_messagingSenderId,
  appId: process.env.REACT_APP_appId
};



firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
function ApplicationForm() {
  const [studentName, setStudentName] = useState('');
  const [parentName, setParentName] = useState('');
  const [studentPhone, setStudentPhone] = useState('');
  const [parentPhone, setParentPhone] = useState('');
  const [parentMail, setParentMail] = useState('');
  const [addr, setAddr] = useState('');
  const [discordId, setDiscordId] = useState('');
  const handleSubmit = (event) => {
    event.preventDefault();
    db.collection('students').add({
      studentName,
      parentName,
      studentPhone,
      parentPhone,
      parentMail,
      addr,
      discordId
    })
    .then(() => {
      console.log('Document successfully written!');
      setStudentName('');
      setParentName('');
      setStudentPhone('');
      setParentPhone('');
      setParentMail('');
      setAddr('');
      setDiscordId('');
    })
    .catch((error) => {
      console.error('Error writing document: ', error);
    });
  };
  return (
    
    <div className='form-container'>
    <form onSubmit={handleSubmit} className='form-group'>
      <label >
        Tanuló neve *
        <input type="text" value={studentName} minLength="5" pattern="[A-zÀ-ú ]{1,32}" onChange={(e) => setStudentName(e.target.value)} required/>
      </label>
      <label>
      Kapcsolattartó (szülő neve) *
        <input type="text" value={parentName} minLength="5" pattern="[A-zÀ-ú ]{1,32}" onChange={(e) => setParentName(e.target.value)} required />
      </label>
      <label>
      Tanuló elérhetősége (formátum:+36301234567) *
        <input type="tel" value={studentPhone} pattern="^\+[0-9]{11}$" onChange={(e) => setStudentPhone(e.target.value)} required/>
      </label>
      <label>
      Szülő elérhetősége (formátum:+36301234567) *
        <input type="tel" value={parentPhone} pattern="^\+[0-9]{11}$" onChange={(e) => setParentPhone(e.target.value)} required/>
      </label>
      <label>
      Szülő e-mail címe *
        <input type="email" value={parentMail}  onChange={(e) => setParentMail(e.target.value)} required/>
      </label>
      <label>
      Állandó lakcím (számlához) *
        <input type="text" value={addr} onChange={(e) => setAddr(e.target.value)} required/>
      </label>
      <label>
      Tanuló discord azonosítója *
        <input type="text" value={discordId} onChange={(e) => setDiscordId(e.target.value)} required />
      </label>
      <button type="submit" className='btn'>Elküld</button>
    </form>
    </div>
  );
}

export default ApplicationForm;