import './App.css';
import HomePage from './HomePage';
import Footer from './footer/Footer';
import DataSec from './gdpr/DataSec';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <Router>
    <div className="App">
      
      <Routes>  
                <Route path="/" element={<HomePage />}/>
                 <Route path="/adatvedelem" element={<DataSec />}/>
      </Routes>
      <Footer/>
    </div>
    </Router>
  );
}

export default App;
