import './Header.css';
import { HashLink as AncLink } from 'react-router-hash-link';

function Header() {
    return (
      <header class="header_base">
        <div class="logo"><img src={require('./media/logo.png')} alt="logo" class="logoimg"></img></div>
        <div class="navButtons">
        <AncLink to="#base" class="header-element">Kezdőlap</AncLink>
        <AncLink to="#rolam" class="header-element">Rólam</AncLink>
        <AncLink to="#kurzusok" class="header-element">Képzések</AncLink>
        </div>
      </header>
    );
  }
  
  export default Header;