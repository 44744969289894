import './Base.css';
import React, { useRef, useState, useEffect } from "react";
import { HashLink as AncLink } from 'react-router-hash-link';

interface Props {
    backgroundImage: string;
    heightIn: string;
  }


const Base: React.FC<Props> = ({ backgroundImage, heightIn, startParallex }) => {
    const [scrollY, setScrollY] = useState(0);
    const [mobile, setMobile] = useState(false);
    const imageRef = useRef(null);

    useEffect(() => {
        function handleWindowResize() {
          setMobile(window.innerWidth < 1000);
        }
        handleWindowResize();
        window.addEventListener('resize', handleWindowResize);
        return () => {
          window.removeEventListener('resize', handleWindowResize);
        };
      }, []);

    useEffect(() => {
        const handleScroll = () => {

          setScrollY(window.scrollY);
        };
          window.addEventListener("scroll", handleScroll);
        return () => {
          window.removeEventListener("scroll", handleScroll);
        };
      }, []);
    
      const DivStyles = 
        mobile
        ? {backgroundPosition: `center`}
        : {backgroundPosition: `center ${scrollY *   -0.02}px`}
      ;
    
    return(
        <div ref={imageRef} style={{...DivStyles, backgroundImage: `url(${backgroundImage})`, height:`${heightIn}vh`}} class="background_container" id="base">
            <div className="next_course">
                <div className="next_course_text"> <h1 className="next_course_question">Jelentkeznél?</h1>
                <h1 className="next_course_time">A következő felvételi felkészítő ekkor indul: <u>2023. 07. 17.</u></h1></div>
                <div className="course_link_cont"><AncLink to="#kurzusok" className="course_link">Vágjunk bele!</AncLink></div>
            </div>
            <div className="quote">
                <h1>"A tanulás nem más, mint annak felfedezése, hogy valami lehetséges."</h1>
                                                                  <p>Fritz Perls</p>
            </div>
        </div> 
    );
};

export default Base;
