import './Bio.css';
import { useState, useEffect } from "react";


function Bio(){
    const [className, setClassName] = useState("");
    useEffect(() => {
        function reveal() {
            var reveals = document.querySelectorAll(".reveal2")
            for (var i = 0; i < reveals.length; i++) {
                var windowHeight = window.innerHeight;
                var elementTop = reveals[i].getBoundingClientRect().top;
                var elementVisible = 120;
              }
              if (elementTop < windowHeight - elementVisible) {
                setClassName("active");
              } 
              else
              {
                setClassName("");
              }
            }
            window.addEventListener("scroll", reveal);
            return () => {
                window.removeEventListener("scroll", reveal);
              };
      }, []);
    return(
        <div class={`bioContainer reveal2 ${className}`} >
            <div id='rolam'>
                <h1> 
                            Üdvözöllek!<br/>
                            A nevem Horváth Zsófia.<br/> 
                            
                </h1>
                <p>
                            Az Eötvös Loránd Tudományegyetemen végeztem tanárszakon.
                            6 éve korrepetálok már kicsiket, nagyokat és felnőtteket történelem, magyar és matematika tantárgyakból. 
                            Sikeresen készítenem fel a diákokat kisebb-nagyobb dolgozatokra, központi felvételire és érettségire.<br/>
                            Jelenleg online is szívesen tanítok, megfelelő eszközöknek hála ugyanolyan hatékony tud lenni, mint a személyes találkozó.
                </p>
            </div>
            <img src={require('../media/mypic.png')} alt='mypicture' class='profilePic'></img>
        </div>
    );
}

export default Bio;