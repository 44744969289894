import './Reviews.css';

function Reviews(){
    return(
    <div class="review-container">
        <div>
            <div class="review-item">
                <blockquote class="review-rating">"Zsófi két éve foglalkozik a fiammal. Matekból, és magyarból is korrepetálja. Türelmes, kitartó, és érthetően elmagyarázza a dolgokat."</blockquote>
                <p class="review-author">(Beatrix, szülő)</p>
            </div>
            <div class="review-item">
                <blockquote class="review-rating">"Szívből ajánlom Zsófiát. Az ADHD-s fiam az országos átlag feletti írásbeli felvételit írt a segítségével.  "
                                    </blockquote>
                <p class="review-author">(Emőke, szülő)</p>
            </div>
            <div class="review-item">
                <blockquote class="review-rating">"Zsófi nagyon sokat segített nekem amióta ismerem a matekban, az ő segítségével sikerült túljutnom nehézségeken. Nagyon könnyen megtaláltam vele a közös hangot, a matekot a egyszerűen tudja magyarázni.<br/> Köszönök mindent Zsófi! "
                                    </blockquote>
                <p class="review-author">(Panka, 18 éves)</p>
            </div>
        </div>
            <div class="review-item">
                <blockquote class="review-rating">"Zsófi:<br/>
                                        Családunkban külön jelentéssel bír, még a neve is.<br/>
                                        <br/>
                                        A hosszú évek alatt mindig számíthattunk támogatására, nagy fokú rugalmasságára, és türelmére, szakmai tudására.<br/>
                                        Kedves humorával még a morcos kamasznak is kedvet tud csinálni a tanuláshoz, fel tudja érdeklődését kelteni olyan témák iránt is, aminek koránál fogva csak dacból is ellen áll. Hihetetlen tudásbázisával nincs lehetetlen vagy megoldhatatlan feladat és tantárgy.<br/>
                                        Szóval, Zsófi nálunk annyit jelent: támogatás, a lehetetlen kibogozása, kusza kamasz lélek motiválója, türelem oszlopa, és a szuper megoldások tudója, átadója."</blockquote>
                <p class="review-author">(Bea, szülő)</p>
            </div>
            <div class="review-item">
                <blockquote class="review-rating">"Zsófinak sokat köszönhetünk. Évek óta foglalkozik a lányommal. <br/>
                                    Nagyon kedves de határozott és iránymutató. Kifejezetten jó tanárnak tartom és hálás vagyok a munkájáért.<br/>
                                    Remélem még sok diákkal megszereteti a matematikát. ❤"
                                    </blockquote>
                <p class="review-author">(Hencsó, szülő)</p>
            </div>
           
            
            <div class="review-item">
                <blockquote class="review-rating">"Zsófit egy kedves ismerősöm által ismertem meg. Mai napig hálás vagyok a sorsnak! Kislányom éppen készült a központi felvételi vizsgához, és emiatt kértem Zsófi segítségét! Tudni kell, hogy Gyermekem sajnos nagyon sokat hiányzott betegsegek az iskolából, így elég nagy lemaradásai voltak. Tehát nekünk még a felkészülés mellett pótolni és felzárkózni is kellett. Soha nem hagyta magára a lányomat, és vele együtt engem sem. Bármit kérdeztem, volt hogy értetlenkedve is ő mindig készséges, türelmes és segítőkész volt.<br/>
Megemliteném még az anyagiakat, ami számomra nagyon elfogadható volt . Ezzel kapcsolatban is mindig rugalmas volt, amit nagyon köszönök neki . <br/>
Tehát Gyermekem a lehető legjobban teljesített számomra a központi felvételi vizsgán és mind Zsófira, mind a lányomra is NAGYON büszke vagyok.<br/>
De a legfontosabb amit kiemelnék az-az ,hogy a lányom SNI-s tanuló. Tehát olyan értelemben, hogy sokkal több türelem és magyarázás szükséges neki, és ezt Zsófi rettenetesen jól kezelte és tényleg a legjobb tudása szerint tanította gyermekemet! <br/>
Tehát csak köszönettel és hálával tartozom neki,és mégegyszer mindnet köszönök neki.  "
                                    </blockquote>
                <p class="review-author">(Erika, szülő)</p>
            </div>
            
</div>
    );
}

export default Reviews;