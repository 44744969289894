import './HomeContent.css';
import { useState, useEffect } from "react";




function HomeContent() {
    const [className, setClassName] = useState("");
    useEffect(() => {
        function reveal() {
            var reveals = document.querySelectorAll(".reveal")
            for (var i = 0; i < reveals.length; i++) {
                var windowHeight = window.innerHeight;
                var elementTop = reveals[i].getBoundingClientRect().top;
                var elementVisible = 150;
              }
              if (elementTop < windowHeight - elementVisible) {
                setClassName("active");
              } 
              else
              {
                setClassName("");
              }
            }
            window.addEventListener("scroll", reveal);
            return () => {
                window.removeEventListener("scroll", reveal);
              };
      }, []);
    
   
    return (
        <div className={`contentContainer reveal ${className}`}>
            <><div class="imageContainer">
            <img src={require('../media/diplomacat.png')} alt="catimage" class="textImage"></img>
        </div><div class="textContainer">
                <h1>Vége a matematikával kapcsolatos frusztrációnak és túlterheltségnek!
                    A matek kurzusomon elsajátíthatod a tantárgy titkait.
                    A módszeremmel könnyen és hatékonyan megtanulhatod a matematika alapjait,
                    melyeket sikeresen használhatsz a mindennapokban!</h1>
            </div></>
        </div>
    );
}

export default HomeContent;