import './DataSec.css';
import { Link} from 'react-router-dom';
function DataSec() {
    const handleLinkClick = () => {
        window.scrollTo(0, 0);
      };
    return(
        <>
        <Link to="/" onClick={handleLinkClick} className="backButton">Vissza</Link> 
       <div className='datasec-cont'>
       <h1>Adatkezelési tájékoztató</h1>

                <h2>1. AZ ADATKEZELŐ MEGNEVEZÉSE</h2>

                <p>Adatkezelő: Horváth Zsófia</p>

                <p>Székhely és levelezési cím: 2040 Budaörs, Szakály Mátyás utca 29. fs. 2.</p>

                <p>hzsofia96@gmail.com</p>

                <p>Telefon: +36-30-419-9202</p>

                <h2>2. AZ ADATKEZELÉS ALAPJÁUL SZOLGÁLÓ JOGSZABÁLYOK</h2>

                <p>Az Európai Parlament és a Tanács (EU) 2016/679 Rendelete (2016. április 27.) a természetes személyeknek a személyes adatok kezelése tekintetében történő védelméről és az ilyen adatok szabad áramlásáról, valamint a 95/46/EK irányelv hatályon kívül helyezéséről (a továbbiakban: GDPR)</p>

                <h2>3. A REGISZTRÁCIÓ SORÁN MEGADOTT ADATOKHOZ KAPCSOLÓDÓ ADATKEZELÉS</h2>

                <p>NÉV/ SZÜLŐ NEVE</p>

                <p>A név felhasználónak az adatbázisban történő azonosításához azért szükséges, mivel Horváth Zsófia csak ügyfeleivel veszi föl a kapcsolatot. Ezen felül Horváth Zsófia és a tanuló/tanuló szülőjével közötti kapcsolattartás célját szolgálja.</p>

                <p>E-MAIL CÍM/ SZÜLŐ E-MAIL CÍME, TELEFONSZÁM</p>

                <p>Az e-mail cím szolgáltatásra jelentkező felhasználónak az adatbázisban történő azonosításához elengedhetetlenül szükséges. Ezen felül Horváth Zsófia és tanuló/tanuló szülője közötti kapcsolattartás célját szolgálja, illetve a tanuló szülője e-mail címére fognak érkezni a számlák.</p>

                <p>ÁLLANDÓ LAKCÍM</p>

                <p>A név felhasználónak az adatbázisban történő azonosításához azért szükséges, mivel Horváth Zsófia csak ügyfeleivel veszi föl a kapcsolatot. Ezen felül Horváth Zsófia a számlázáshoz használja fel.</p>

                <p>DISCORD AZONOSÍTÓ</p>

                <p>A szolgáltatás felülete, amennyiben online zajlik, a  <a href="http://www.discord.com">www.discord.com</a>, emiatt a tanuló azonosítójára feltétlenül szükség van.</p>

                <p>Horváth Zsófia felhívja a figyelmet, hogy a fenti adatok megadása kötelező. Nem szükséges azonban, hogy az e-mail cím vagy a discord azonosító az érintett nevét tartalmazza, tetszőleges cím is megadható. Az érintett szabadon dönthet arról, hogy olyan létező e-mail címet ad-e meg, amely a kilétére utaló információt tartalmaz.</p>

                <h3>3.1.1. AZ ADATKEZELÉS JOGALAPJA</h3>

                <p>Az adatkezelés jogalapja az üzenet küldése során megadott fenti adatok tekintetében az érintett hozzájárulása.</p>

                <h3>3.1.2. AZ ADATKEZELÉS IDŐTARTAMA</h3>

                <p>Horváth Zsófia az üzenet küldése során megadott kapcsolattartási adatokat a felhasználó hozzájárulásának visszavonásáig kezeli.</p>

                <h3>3.2. ADATKEZELÉS A FELVÉVE - FELVÉTELI ÉS ÉRETTSÉGI ELŐKÉSZÍTŐ ZSÓFIVAL  FACEBOOK-OLDALÁN</h3>

                <p>Horváth Zsófia a Felvéve - Felvételi és érettségi előkészítő Zsófival gondolati világa, szolgáltatásai, programjai megismertetése, népszerűsítése céljából Facebook-oldalt tart fenn.</p>

                <h3>3.2.1. AZ ADATKEZELÉS JOGALAPJA</h3>

                <p>Az adatkezelés jogalapja a fenti adatok tekintetében az érintett önkéntes regisztrációja.</p>

                <h3>3.2.2. AZ ADATKEZELÉSRE VONATKOZÓ TÁJÉKOZTATÁS</h3>

                <p>A látogatókra az Adatvédelmi- és Szolgáltatási Feltételek irányadók.</p>

                <p>Jogellenes, vagy sértő tartalom publikálása esetén Horváth Zsófia előzetes értesítés nélkül kizárhatja az érintettet a tagok közül, vagy törölheti hozzászólását.</p>

                <p>Horváth Zsófia nem felel a felhasználók által közzétett jogszabályt sértő adattartalmakért, hozzászólásokért.</p>

                <p>Horváth Zsófia nem felel semmilyen, a működéséből adódó hibáért, üzemzavarért vagy a rendszer működésének megváltoztatásából fakadó problémáért.</p>

                <h3>3.3.1. AZ ADATKEZELÉS JOGALAPJA</h3>

                <p>Az adatkezelés jogalapja a fenti adatok tekintetében az érintett önkéntes regisztrációja.</p>

                <h3>3.3.2. AZ ADATKEZELÉSRE VONATKOZÓ TÁJÉKOZTATÁS</h3>

                <p>A látogatókra az Adatvédelmi- és Szolgáltatási Feltételek irányadók.</p>

                <p>Jogellenes, vagy sértő tartalom publikálása esetén Horváth Zsófia előzetes értesítés nélkül kizárhatja az érintettet a tagok közül, vagy törölheti hozzászólását.</p>

                <p>Horváth Zsófia nem felel a felhasználók által közzétett jogszabályt sértő adattartalmakért, hozzászólásokért.</p>

                <p>Horváth Zsófia nem felel semmilyen, a működéséből adódó hibáért, üzemzavarért vagy a rendszer működésének megváltoztatásából fakadó problémáért.</p>

                <h2>5. AZ ADATOKHOZ VALÓ HOZZÁFÉRÉS ÉS AZ ADATBIZTONSÁGI INTÉZKEDÉSEK</h2>

                <h3>5.1. AZ ADATOKHOZ VALÓ HOZZÁFÉRÉS ÉS AZ ADATTOVÁBBÍTÁS</h3>

                <p>Horváth Zsófia mint Adatkezelő jogosult és köteles minden olyan rendelkezésére álló és általa szabályszerűen tárolt Személyes adatot az illetékes hatóságoknak továbbítani, amely adat továbbítására őt jogszabály vagy jogerős hatósági kötelezés kötelezi. Ilyen Adattovábbítás, valamint az ebből származó következmények miatt az Adatkezelő nem tehető felelőssé.</p>

                <h3>5.2. ADATOK TÁROLÁSA</h3>

                <p>Az adatokat Horváth Zsófia a személyi számítógépén tárolja.</p>

                <p>A személyes adatokhoz Horváth Zsófia férhet hozzá a feladata ellátása érdekében. Horváth Zsófia csak jogszabályban meghatározott módon és célból adja át az általa kezelt személyes adatokat harmadik fél számára.</p>

                <h2>6. AZ ADATKEZELÉSSEL KAPCSOLATOS JOGOK</h2>

                <h3>6.1 A TÁJÉKOZTATÁS KÉRÉSHEZ VALÓ JOG</h3>

                <p>Az érintett személy az 1. pontban megadott elérhetőségeken keresztül, írásban tájékoztatást kérhet Horváth Zsófiától arról, hogy milyen személyes adatait, milyen jogalapon, milyen adatkezelési cél miatt, milyen forrásból, mennyi ideig kezeli, Horváth Zsófia kinek, mikor, milyen jogalap szerint, mely személyes adataihoz biztosított hozzáférést vagy kinek továbbította a személyes adatait. Horváth Zsófia az érintett kérelmét legfeljebb egy hónapon belül, az általa megadott elérhetőségre küldött e-mailben teljesíti.</p>

                <h3>6.2. A HELYESBÍTÉSHEZ VALÓ JOG</h3>

                <p>Az érintett személy az 1. pontban megadott elérhetőségeken keresztül, írásban kérheti, hogy Horváth Zsófia módosítsa valamely személyes adatát (például bármikor megváltoztathatja az e-mail címét vagy postai elérhetőségét). Horváth Zsófia a kérelmet legfeljebb egy hónapon belül teljesíti, és erről az általa megadott elérhetőségre küldött e-mailben értesíti.</p>

                <h3>6.3. A TÖRLÉSHEZ VALÓ JOG</h3>

                <p>Az érintett személy az 1. pontban megadott elérhetőségeken keresztül, írásban kérheti Horváth Zsófiától a személyes adatainak a törlését. A törlési kérelmet Horváth Zsófia abban az esetben utasítja el, ha Horváth Zsófiát a személyes adatok további tárolására kötelezi. Ilyen eset például az, ha számviteli jogszabályok által előírt határidő nem telt le. Amennyiben azonban nincs ilyen kötelezettség, akkor Horváth Zsófia a kérelmet legfeljebb egy hónapon belül teljesíti, és erről az e célból megadott elérhetőségre küldött e-mailben értesíti az érintettet.</p>

                <h3>6.4. A ZÁROLÁSHOZ (ADATKEZELÉS KORLÁTOZÁSÁHOZ) VALÓ JOG</h3>

                <p>Az érintett személy az 1. pontban megadott elérhetőségeken keresztül, írásban kérheti, hogy a személyes adatait Horváth Zsófia zárolja (az adatkezelés korlátozott jellegének egyértelmű jelölésével és az egyéb adatoktól elkülönített kezelés biztosításával). A zárolás addig tart, amíg az érintett által megjelölt indok szükségessé teszi az adatok tárolását. Az adat zárolását kérheti az érintett például abban az esetben, ha úgy gondolja, hogy a beadványát Horváth Zsófia jogellenesen kezelte, azonban az általa kezdeményezett hatósági vagy bírósági eljárás érdekében szükséges az, hogy a beadványt Horváth Zsófia ne törölje. Ebben az esetben a hatóság vagy a bíróság megkereséséig Horváth Zsófia tovább tárolja a személyes adatot (például az adott beadványt), ezt követően törli az adatokat.</p>

                <h3>6.5. A TILTAKOZÁSHOZ VALÓ JOG</h3>

                <p>Az érintett személy az 1. pontban megadott elérhetőségeken keresztül, írásban tiltakozhat az adatkezelés ellen, ha Horváth Zsófia a személyes adatot pl. közvélemény-kutatás vagy tudományos kutatás céljából továbbítaná, felhasználná.</p>

                <h2>7. AZ ADATKEZELÉSSEL KAPCSOLATOS JOGÉRVÉNYESÍTÉSI LEHETŐSÉGE</h2>

                <h3>7.1. BÍRÓSÁGI ELJÁRÁS KEZDEMÉNYEZÉSE</h3>

                <p>Az érintett, ha a személyes adatai kezelésének jogellenességét tapasztalja, polgári pert kezdeményezhet Horváth Zsófia ellen. A per elbírálása a törvényszék hatáskörébe tartozik. A per – az érintett választása szerint – az érintett lakóhelye szerinti törvényszék előtt is megindítható (a törvényszékek felsorolását és elérhetőségét az alábbi linken keresztül tekintheti meg: http://birosag.hu/torvenyszekek).</p>

                <h3>7.2. NAIH ELJÁRÁSA</h3>

                <p>Az érintett, ha a személyes adatai kezelésének jogellenességét tapasztalja, a Nemzeti Adatvédelmi és Információszabadság Hatóságnál online panaszt tehet. Az elérhető link: https://www.naih.hu/online-uegyinditas.html. Személyes időpontot egyeztetni kedden és csütörtökön 9:00–12:00 és 13:00–16:00 óra között a következő telefonszámon lehet: +36 (1) 391-1400. Felhívjuk figyelmét, hogy a Hatóság a panaszokat csak abban az esetben vizsgálja ki, amennyiben az érintett a Hatóságnál tett bejelentését megelőzően már megkereste az adatkezelőt (Horváth Zsófiát) panaszával kapcsolatban, és az nem vezetett eredményre.</p>

                <p>Budapest, 2023. április 17.</p>

    </div> 
    </>
    );
}


export default DataSec;