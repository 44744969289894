import Base from './Base.js';
import HomeContent from './homepage/HomeContent';
import Header from './Header.js';
import CourseTimes from './courseTimes/CourseTime';
import Bio from './bio/Bio';
import Reviews from './reviews/Reviews';

function HomePage (){
    return(
        <>
        <Header />
        <Base  backgroundImage ={require('./media/background.jpeg')} heightIn={100} />
        <HomeContent />
         <Base backgroundImage ={require('./media/thinking_boy.png')} heightIn={60}  />
         <Bio />
        <Base backgroundImage ={require('./media/math_stuff.png')} heightIn={40}  />
        <CourseTimes/>
        <Base backgroundImage ={require('./media/equations.png')} heightIn={40}  />
        <Reviews/>
        </>
      );
    }


    export default HomePage;