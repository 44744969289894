import './CourseTimes.css';
import { useState } from "react";
import ApplicationForm from '../applicationForm/ApplicationFrom';

function CourseTimes() {
    const [displayText, setDisplayText] = useState(false);
    const [selectedCourse, setSelectedCourse] = useState('');
    const [courseText, setCourseText] = useState('');
    const [displayApplication,setDisplayApplication]=useState(false);
  
    const toggleDisplayText = (courseName) => {
      setDisplayText(!displayText);
      setSelectedCourse(courseName);
      if (courseName ==='Érettségi felkészítő'){
        setCourseText("SZERDA (18.00 – 19.30)\n(90 perc)\nIndulás:2023. 02. 01.\n•\tOnline felület: Discord\n•\tCsoportos (4-6 fő)\n•\tIdőpontok (heti 1 alkalom):\no\tfebruár 1., 8., 22.\no\tmárcius 1., 8., 22., 29.\no\táprilis 12., 19., 26.\n•\tAz órák költsége:\n•\tA) egyösszegű befizetés esetén 40.000 Ft\nB) részletfizetés esetén 44.000 Ft\no\tfebruár: 13 200 Ft\no\tmárcius 17 600 Ft\no\táprilis 13 200 Ft\n\n- Az első fizetési határidő minkét opció esetén: január 31.\n- további részletfizetési időpontok: minden hónap 10. napjáig");
      } else if (courseName ==='Nyári intenzív felvételi felkészítő'){
        setCourseText("5 alkalmas\nONLINE FELVÉTELI FELKÉSZÍTŐ \n\nJÚLIUS 24-28. (16.00 – 17.30)\n(90 perc)\nIndulás: 2023.07.24.\n•\tOnline felület: Discord\n•\tCsoportos (4-6 fő)\n•\tNapi 1 alkalom hétfőtől péntekig\n\n•\tAz órák költsége: 20.000 Ft\n- A fizetési határidő mindkét opció esetén: július 20.");
      } else if (courseName==='10 alkalmas nyári online felvételi felkészítő'){
        setCourseText("10 alkalmas NYÁRI\nONLINE FELVÉTELI FELKÉSZÍTŐ \n\nSZERDA (16.00 – 17.30)\n(90 perc)\nIndulás: 2023.07.05.\n•\tOnline felület: Discord\n•\tCsoportos (4-6 fő)\n•\tIdőpontok (heti 2 alkalom):\n\t- július 5., 6., 12., 13., 19., 20.\n\t- augusztus 2., 3., 9., 10.\n•\tAz órák költsége: 40.000 Ft");
      }
      else if (!displayText){
        setCourseText("");
      }
    };
  
    return (
      <><div className='courseChoiceContainer' id='kurzusok'>
        <div className={`optionCon ${displayText ? 'toggleDisplay' : ''}`} onClick={() => toggleDisplayText('10 alkalmas nyári online felvételi felkészítő')}><h1>Nyári <br />ONLINE<br /> felvételi felkészítő<br />10 alkalom</h1></div>
        <div className={`optionCon ${displayText ? 'toggleDisplay' : ''}`} onClick={() => toggleDisplayText('Érettségi felkészítő')}><h1>Érettségi felkészítő<br />ONLINE<br />10 alkalom</h1></div>
        <div className={`optionCon ${displayText ? 'toggleDisplay' : ''}`} onClick={() => toggleDisplayText('Nyári intenzív felvételi felkészítő')}><h1>Nyári intenzív felvételi felkészítő<br />ONLINE<br />(egy hetes)</h1></div>
        {displayText && (
          <div className='textContainer'>
            <h2 class='courseTitle'>{selectedCourse}</h2>
            <p class='courseText'>{courseText}</p>
            <button onClick={() => { setDisplayApplication(true); } } className='backBtn'>Jelentkezés</button>
            <button onClick={() => { setDisplayText(false); setCourseText("");setDisplayApplication(false); } } class='backBtn'>Vissza</button>
            {displayApplication && (<div className='googleForm'><button onClick={()=>{setDisplayApplication(false);}} className='closeApplication'>Bezár</button><ApplicationForm/>
      </div>)}
          </div>
          
        )}
      </div> </>
    );
}

export default CourseTimes;