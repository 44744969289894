import './Footer.css';
import { Link} from 'react-router-dom';


function Footer() {
    const handleLinkClick = () => {
        window.scrollTo(0, 0);
      };
    return(
        <div className='footer'>
            <p>Horváth Zsófia ev. | 2040 Budaörs | Szakály Mátyás utca 29.B. fs. 2. | Adószám: 58993570-1-33</p><Link to="/adatvedelem" onClick={handleLinkClick}>Adatvédelmi nyilatkozat</Link>
            
        </div>
        
    );
}

export default Footer;